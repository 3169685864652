import { Check, Exclamation, Pause, Sync } from '@air/next-icons';
import { animated, config, useSpring } from '@react-spring/web';
import { memo } from 'react';

import { SimpleStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';

export const CompletedAvatar = memo(() => {
  const [props] = useSpring(
    () => ({
      from: { scale: 0 },
      to: { scale: 1 },
      config: config.wobbly,
    }),
    [],
  );

  return (
    <div aria-hidden className="mr-3 flex h-10 w-10 items-center justify-center rounded-full bg-teal-2">
      <animated.div className="flex items-center justify-center" style={props}>
        <Check className="h-6 w-6 fill-teal-10" />
      </animated.div>
    </div>
  );
});

CompletedAvatar.displayName = 'CompletedAvatar';

export const ErroredAvatar = memo(() => {
  const [props] = useSpring(
    () => ({
      from: { scale: 0 },
      to: { scale: 1 },
      config: config.wobbly,
    }),
    [],
  );

  return (
    <div aria-hidden className="mr-3 flex h-10 w-10 items-center justify-center rounded-full bg-red-3">
      <animated.div className="flex items-center justify-center" style={props}>
        <Exclamation className="h-6 w-6 fill-red-9" />
      </animated.div>
    </div>
  );
});

ErroredAvatar.displayName = 'ErroredAvatar';

const PausedAvatar = () => (
  <div aria-hidden className="mr-3 flex h-10 w-10 items-center justify-center rounded-full bg-grey-4">
    <Pause className="h-6 w-6 fill-grey-10" />
  </div>
);

export const ProgressingAvatar = () => {
  return (
    <div
      aria-hidden
      className="mr-3 flex h-10 w-10 animate-spin items-center justify-center rounded-full bg-indigo-1 motion-reduce:animate-none"
    >
      <Sync className="h-6 w-6 fill-indigo-9" />
    </div>
  );
};

export const StatusAvatar = memo(({ status }: { status: SimpleStatus }) => {
  if (status === SimpleStatus.completed) return <CompletedAvatar />;
  if (status === SimpleStatus.errored) return <ErroredAvatar />;
  if (status === SimpleStatus.paused) return <PausedAvatar />;
  return <ProgressingAvatar />;
});

StatusAvatar.displayName = 'StatusAvatar';
