import { ConfigurableView, ViewTypeName, VisibleColumn, VisibleColumnType } from '@air/api/types';
import { arrayMove } from '@dnd-kit/sortable';
import { createReducer } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import {
  hideKanbanColumnAction,
  removeFieldFromConfigurableCurrentAndSavedView,
  reorderKanbanColumnsByIdAction,
  resetConfigViewsAction,
  setCardSizeAction,
  setConfigurableViewFieldsAction,
  setCurrentViewTypeAction,
  setFieldsVisibilityAction,
  setKanbanGroupByAction,
  setKanbanVisibleColumnsAction,
  setSavedConfigurableViewAction,
  setSavedConfigurableViewsAction,
  setSortingFieldAction,
  setThumbnailPreferenceAction,
  showKanbanColumnAction,
} from '~/store/configViews/actions';
import { ConfigViewsState } from '~/store/configViews/types';
import { getDefaultSortDirection } from '~/store/configViews/utils';

const initialState: ConfigViewsState = {
  savedConfigurableViews: [],
  configurableViewFields: [],
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSavedConfigurableViewsAction, (state, action) => {
      /**
       * board.views sometimes comes back with additional properties that affect the
       * changed state produced by immer
       */
      const nextSavedConfigurableViews: ConfigurableView[] = action.payload.savedConfigurableViews.map((view) => ({
        id: view.id,
        isDefault: view.isDefault,
        sortFields: view.sortFields,
        visibleFields: view.visibleFields,
        viewType: view.viewType,
        groupBy: view.groupBy,
        visibleColumns: view.visibleColumns,
        customFieldsVisible: false,
        metadataFieldsVisible: false,
        thumbnailStyle: view.thumbnailStyle,
        cardSize: view.cardSize,
      }));

      // view IDs can be randomly generated if not previously saved. In this case, we are getting new `board.views`
      // we DO want it to update, because fresh data is good! But we DON'T want to override the local changes in this case
      // we can check this by seeing if ANY viewId is the same as it was before.
      if (
        !state.savedConfigurableViews.some((view) =>
          nextSavedConfigurableViews.some((nextView) => nextView.id === view.id),
        )
      ) {
        const nextCurrentConfigurableView =
          nextSavedConfigurableViews.find((view) => view.isDefault) || nextSavedConfigurableViews[0];

        state.currentConfigurableView = nextCurrentConfigurableView;
      } else if (state.currentConfigurableView) {
        // just handles undefined case
        // ensure the id is correct based on new info
        state.currentConfigurableView.id =
          nextSavedConfigurableViews.find((v) => v.viewType === state.currentConfigurableView?.viewType)?.id ||
          state.currentConfigurableView.id;
      }

      state.savedConfigurableViews = nextSavedConfigurableViews;
    })
    .addCase(resetConfigViewsAction, (state) => {
      state.currentConfigurableView = undefined;
      state.savedConfigurableViews = [];
    })
    .addCase(setSavedConfigurableViewAction, (state, action) => {
      state.savedConfigurableViews.forEach((view, index) => {
        if (view.id === action.payload.configurableView.id) {
          // this is the one to save
          state.savedConfigurableViews[index] = action.payload.configurableView;
          state.currentConfigurableView = action.payload.configurableView;
        } else {
          // ensure that the other views are no longer the default
          state.savedConfigurableViews[index].isDefault = false;
        }
      });
    })
    .addCase(setConfigurableViewFieldsAction, (state, action) => {
      state.configurableViewFields = action.payload.configurableViewFields;
    })
    .addCase(setCurrentViewTypeAction, (state, action) => {
      const nextView = state.savedConfigurableViews.find((view) => view.viewType.name === action.payload.viewTypeName);
      if (nextView) {
        // oddly enough, if you do not spread a new object here, currentConfigViewHasChangesSelector does not register the change
        state.currentConfigurableView = { ...nextView };
        state.currentConfigurableView.isDefault = true;
      }
    })
    .addCase(setFieldsVisibilityAction, (state, action) => {
      const { fields, nextIsFieldVisible, defaultToSavedVisibility } = action.payload;

      if (!state.currentConfigurableView) return;
      if (nextIsFieldVisible) {
        fields.forEach((field) => {
          const savedView = state.savedConfigurableViews.find((v) => v.id === state.currentConfigurableView?.id);
          const shouldToggleOnFromSavedView =
            !!defaultToSavedVisibility &&
            !!savedView?.visibleFields.some((f) =>
              f.customFieldId ? f.customFieldId === field.customFieldId : f.name === field.name,
            );
          const isCurrentlyVisible = !!state.currentConfigurableView?.visibleFields.some((f) =>
            f.customFieldId ? f.customFieldId === field.customFieldId : f.name === field.name,
          );
          const shouldToggleOn = !defaultToSavedVisibility || shouldToggleOnFromSavedView;
          if (shouldToggleOn && !isCurrentlyVisible) {
            state.currentConfigurableView?.visibleFields.push(field);
          }
        });
      } else {
        state.currentConfigurableView.visibleFields = state.currentConfigurableView.visibleFields.filter((f) => {
          if (f.customFieldId) {
            // include this item if is not in the array of options given
            return !fields.some((field) => f.customFieldId === field.customFieldId);
          } else {
            return !fields.some((field) => f.name === field.name);
          }
        });
      }
    })
    .addCase(setSortingFieldAction, (state, action) => {
      const { sortingFieldName, direction } = action.payload;
      const nextDirection = direction || getDefaultSortDirection(sortingFieldName);
      const sortOption = state.configurableViewFields.find((field) => field.name === sortingFieldName);
      state.currentConfigurableView!.sortFields[0] = {
        name: sortingFieldName,
        customFieldId: sortOption?.customFieldId,
        direction: nextDirection,
      };
    })
    .addCase(setThumbnailPreferenceAction, (state, action) => {
      if (!state.currentConfigurableView) return;
      state.currentConfigurableView.thumbnailStyle = action.payload.thumbnailPreference;
    })
    .addCase(setCardSizeAction, (state, action) => {
      if (!state.currentConfigurableView) return;
      state.currentConfigurableView.cardSize = action.payload.cardSize;
    })
    .addCase(removeFieldFromConfigurableCurrentAndSavedView, (state, action) => {
      const { fieldId } = action.payload;
      state.configurableViewFields = state.configurableViewFields.filter(
        (viewField) => viewField.customFieldId !== fieldId,
      );
      if (state.currentConfigurableView) {
        state.currentConfigurableView.visibleFields = state.currentConfigurableView.visibleFields.filter(
          (f) => f.customFieldId !== fieldId,
        );
      }
      state.savedConfigurableViews.forEach(
        (configView, index) =>
          (state.savedConfigurableViews[index] = {
            ...configView,
            visibleFields: configView.visibleFields.filter((visibleField) => visibleField.customFieldId !== fieldId),
          }),
      );
    })
    .addCase(setKanbanGroupByAction, (state, action) => {
      if (!state.currentConfigurableView) return;
      state.currentConfigurableView.groupBy = { customFieldId: action.payload.customFieldId };
    })
    .addCase(setKanbanVisibleColumnsAction, (state, action) => {
      const savedKanbanView = state.savedConfigurableViews.find((v) => v.viewType.name === ViewTypeName.kanban);
      let visibleColumns: VisibleColumn[] = [];
      if (
        savedKanbanView?.visibleColumns &&
        savedKanbanView?.groupBy?.customFieldId === action.payload.customFieldValues[0].customFieldId
      ) {
        visibleColumns = savedKanbanView.visibleColumns;
      } else {
        visibleColumns = [{ type: VisibleColumnType.unassignedCustomFieldValue }];
        action.payload.customFieldValues.forEach((cfValue) =>
          visibleColumns.push({
            type: VisibleColumnType.customFieldValue,
            customFieldValueId: cfValue.id,
            value: cfValue.value,
          }),
        );
      }

      if (!state.currentConfigurableView) return;
      state.currentConfigurableView.visibleColumns = visibleColumns;
    })
    .addCase(reorderKanbanColumnsByIdAction, (state, action) => {
      const { activeColumnId, overColumnId } = action.payload;
      const indexOfActive = state.currentConfigurableView?.visibleColumns?.findIndex((col) => {
        if (col.type === VisibleColumnType.customFieldValue) {
          return col.customFieldValueId === activeColumnId;
        }
      });
      const indexOfOver = state.currentConfigurableView?.visibleColumns?.findIndex((col) => {
        if (col.type === VisibleColumnType.customFieldValue) {
          return col.customFieldValueId === overColumnId;
        }
      });
      if (
        state.currentConfigurableView?.visibleColumns &&
        !isUndefined(indexOfActive) &&
        !isUndefined(indexOfOver) &&
        ![indexOfActive, indexOfOver].includes(-1)
      ) {
        state.currentConfigurableView.visibleColumns = arrayMove(
          state.currentConfigurableView.visibleColumns,
          indexOfActive,
          indexOfOver,
        );
      }
    })
    .addCase(hideKanbanColumnAction, (state, action) => {
      const { kanbanColumnId } = action.payload;
      const colIndex = state.currentConfigurableView?.visibleColumns?.findIndex((col) => {
        if (col.type === VisibleColumnType.customFieldValue) {
          return col.customFieldValueId === kanbanColumnId;
        } else {
          return kanbanColumnId === col.type;
        }
      });
      if (!isUndefined(colIndex) && colIndex > -1) {
        state.currentConfigurableView?.visibleColumns?.splice(colIndex, 1);
      }
    })
    .addCase(showKanbanColumnAction, (state, action) => {
      const { column } = action.payload;
      const isUnassignedVisible = !!state.currentConfigurableView?.visibleColumns?.some(
        (col) => col.type === VisibleColumnType.unassignedCustomFieldValue,
      );
      const colIndex = isUnassignedVisible ? 1 : 0; // unassigned is always first, so place it after
      state.currentConfigurableView?.visibleColumns?.splice(colIndex, 0, column);
    });
});
