/**
 *
 * @param {String} url - The imgix url to create the CloudFront url from
 * @returns 'https://*.cloudfront.net/filename.jpg'
 */
export const getCloudFrontUrlFromImgixUrl = (url: string) => {
  if (!url) {
    return '';
  }

  const baseImgixUrl = `.imgix.net/`;
  const baseS3Url = process.env.NEXT_PUBLIC_AIR_MEDIA_BUCKET_URL;
  const file = url.split(baseImgixUrl)[1];

  return `${baseS3Url}/${file}`;
};
